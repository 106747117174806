<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <v-row dense>
            <v-spacer />
            <v-col>
              <v-img contain max-width="100" :src="require('@/assets/images/ers-icon-center.png')" alt="" />
            </v-col>
            <v-spacer />
          </v-row>
<!--          <h4>auto<i>Oasis</i> ERS</h4>-->
          <!-- {{ serverReady }} -->
          <h6 class="font-weight-medium mt-5 mb-5">
            <b>ERS Center Login</b>
          </h6>
          <div class="error" v-if="errorMessage != null">{{errorMessage}}</div>
          <v-form>
            <v-text-field
              v-model="loginId"
              label="아이디"
              :rules="loginIdRules"
              required
            />

            <v-text-field
              v-model="ePassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="password"
              :counter="15"
              :rules="nameRules"
              @click:append="show = !show"
            />
            <!--
            <v-checkbox
              v-model="checkbox1"
              label="Remember this computer"
            />
            -->
            <v-btn
              class="mb-4 mt-6"
              block
              color="primary"
              dark
              @click="formSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              로그인
            </v-btn>
            <!--
            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" class="mb-2">
                패스워드를 잊었습니다.
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                to="/app/sessions/sign-up-2"
              >
                새 계정을 만드세요
              </v-btn>
            </div>
            -->
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import serverService from '@/services/server'

export default {
  name: 'Login',
  metaInfo: {
    title: '로그인'
  },
  data() {
    return {
      serverReady: '연결되지 않음',
      show: false,
      password: 'Password',
      checkbox1: true,
      loginId: null,
      ePassword: null,
      loading: false,
      errorMessage: null,
      loginIdRules: [
        (v) => !!v || '반드시 입력해야 합니다.'
      ],
      nameRules: [
        (v) => !!v || '반드시 입력해야 합니다.',
        (v) =>
          (v && v.length <= 15) ||
          '15글자 이하로 입력해 주십시오.'
      ]
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'error'])
  },
  methods: {
    ...mapActions(['login']),
    formSubmit() {
      this.login({ loginId: this.loginId, loginPwd: this.ePassword })
    }
  },
  watch: {
    // loading (val) {
    //   if (!val) return
    //   setTimeout(() => (this.loading = false), 2000)
    // },
    loggedInUser(val) {
      console.log('SignIn---loggedInUser--->', val)
      if (val && val.counselor && val.counselor.userId && val.counselor.userId.length > 0 && val.counselor.isMfAuth) {
        this.loading = true
        this.$router.push('/dashboard')
      }
    },
    error(val) {
      if (val != null) {
        this.errorMessage = val
      }
    }
  },

  mounted() {
    serverService.ready().then(r => {
      this.serverReady = r.message
    })
  }
}
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
